/** COMPANY INFORMATION */
export const GET_COMPANY_INFO = (param) => {
  return `query {
    company(code: "${param}") {
      id
      code
      country
      customerTypeId
      email
      fax
      homePage
      marketId
      name
      tel
      town
      primaryMarket {
        abbreviation
        businessDaysStoT
        cityId
        closeTimeLocal
        delay
        id
        openTimeLocal
        parentId
        regionId
        timeDiff
        timezoneId
        timezoneName
        translationId
        status {
          isOpened
          remainingTime
        }
        timezone {
          name
          nameIANA
        }
      }
    }
  }`;
};

/** COMPANY & CARD CONFIG */
export const GET_WISE_CONFIG = (param) => {
  return `query{companyConfiguration(companyCode: "${param}"){
    companyConfig
    cardConfig
    }
  }`;
};

/** INTRADAY INFORMATION */
export const INTRADAY_QUERY = (duration = 1, dateRange) => {
  return `intraday(
            timeIntervalGrouping: ${duration}
            order: { dateTime: ASC }
            first: 32000
            where: { dateTime: ${dateRange} }
      ) {
      nodes {
        close
        dateTime
      }
    }
  `;
};

/** HISTORICAL INFORMATION */
export const HISTORICALS_QUERY = (dateRange, type) => {
  const whereClause = dateRange ? `where: { dateTime: ${dateRange} }` : "";
  const orderClause = `order: { dateTime: ${type === "week" ? "DESC" : "ASC"
    } } ${type === "week" ? ", first: 5" : ", first: 32000"}`;
  return `
      historicals(
        ${whereClause}
        ${orderClause}
      ) {
        nodes {
          close
          dateTime
        }
      }
  `;
};

//  INTRADAY MAIN QUERY
export const GET_INTRADAY_MAIN = (ids, dateRange, isToday = true) => {
  const QUERY = isToday
    ? INTRADAY_QUERY(1, dateRange)
    : HISTORICALS_QUERY(dateRange);
  return `instrumentByIds(ids: [${ids}]) {
      id
      shareName
      companyCode
      currencyCode
      symbol
      company {
        country
      }
      currentPrice {
        change
        changePercentage
        last
        high
        open
        low
        prevClose
        volume
      }
      market {
        abbreviation
        status {
          isOpened
          remainingTime
        }
        openTimeLocal
        closeTimeLocal
        businessDaysStoT
        timezoneName
        timeDiff
        timezone {
          diffFromServerTime
          translation {
            value
          }
          diff
          name
          nameIANA
        }
        city {
          name
        }
      }
      lastRowChange
      ${QUERY}
    }`;
};

export const GET_SHARE_PERFORMANCE_HISTORY = (duration) => {
  return `performance(period: ${duration}) {
      changePercentage
    }`;
};

export const GET_SHARE_PERFORMANCE = (ids) => {
  return `
      instrumentByIds(ids: [${ids}]) {
        id
        companyCode
        currencyCode
        shareName            
        currentPrice {
          date
          last
          low
          open
          prevClose
          volume
          high
          change
          changePercentage
        }
        oneMonthChange: ${GET_SHARE_PERFORMANCE_HISTORY("ONE_MONTH")}
        threeMonthsChange: ${GET_SHARE_PERFORMANCE_HISTORY("THREE_MONTHS")}
        sixMonthsChange: ${GET_SHARE_PERFORMANCE_HISTORY("SIX_MONTHS")}
        oneYearChange: ${GET_SHARE_PERFORMANCE_HISTORY("ONE_YEAR")}
      }
`;
};

export const GET_ANNOUNCEMENT_BY_DATE = (param) => {
  return `
    company(code: "${param?.company}") {
      pressReleases(
        order: { dateTime: DESC }
        where: { dateTime: { gte: "${param.fromDate}", lte: "${param.toDate
    }" }, languageId: { eq: ${param.languageId || 32
    } }, messageTypeId: { in: [4, 0] } }
        ${param.limit ? "first: 50" : ""}) {
        nodes {
          id
          title
          insertedDate
          dateTime
          hasAttachment
          messageTypeId
          languageId
          attachments(order: { order: DESC }) {
            nodes {
              fileName
              id
              isInBlob
              languageId
              location
              mime
              order
            }
          }
          messageType {
            name
          }
        }
      }
    }
  `;
};

export const GET_ANNOUNCEMENT_BY_ID = (param) => {
  return `query {
    pressrelease(id: ${param.id}) {
      id
      title
      insertedDate
      message
      dateTime
      hasAttachment
      messageTypeId
      languageId
      attachments(order: { order: DESC }) {
        nodes {
          fileName
          id
          isInBlob
          languageId
          location
          mime
          order
        }
      }
      messageType {
        name
      }
    }
  }`;
};

export const SIGNUP_USER = (param) => {
  return `
    mutation {
      signupUser(username: "${param.name}", email: "${param.email}", password: "${param.password}") {
        message
        success
        data {
          createdAt
          email
          isActive
          lastLogin
          passwordHash
          userId
          username
        }
      }
    }
  `;
};

export const LOGIN_USER = (param) => {
  return `
    mutation {
      loginUser(email: "${param.email}", password: "${param.password}") {
        message
        success
        data {
          createdAt
          email
          isActive
          lastLogin
          passwordHash
          userId
          username
        }
      }
    }
  `;
};

export const GET_ANNOUNCEMENT_QA = (param) => {
  return `
  query {
    userAnnouncement(announcement_Id: 0, external_Id: "${param.announcementId}", user_Id: ${param.userId}) { 
      postId: questionId
      userId
      user {
        userName: username
      }
      createdAt
      postedOn: timeAgo  
      highlightContent:highlightedText
      message:questionText
      replyContent:answers {
        userId
        user {
          userName: username
        }
        createdAt
        postedOn:timeAgo
        answerId
        message:answerText
      
      }
      likes:likesCount
      current_user_like_status:hasUserLiked 
    }
  }`;
};

export const UPDATE_ANNOUNCEMENT_QA = (param) => {
  return `
    mutation {
      addPost(
        addAnnouncementModel: {
          announcementId: "${param.announcementId}",
          highlightContent: "${param.highlightContent}",
          message: "${param.message}",
          questionId: ${param.questionId}
          userId: ${param.userId},
        }
      ) {
        message
        success
      }
    }
  `;
};

export const UPDATE_ANNOUNCEMENT_LIKE = (param) => {
  return `
    mutation {
      addLike( 
        addAnnouncementLikeModel: {
          announcementId: ${param.announcementId},
          like: ${param.like},
          userId: ${param.userId},
          externalId: "${param.externalId}",
          questionId: ${param.questionId},
        }
      ) {
        message
        success
      }
    }
  `;
};

export const GET_FINANCIAL_REPORT = (param) => {
  // ANNUAL REPORT
  return `
  query {
    company(code: "${param?.company}") {
          fundamental {
            reports(
              where: { ${param?.period && `reportTypeId: { in: [${param?.period}] },`
    } ${param?.year && `year: { eq: ${param?.year} },`
    } languageCode: { eq: "${param?.languageId || "en"}"} }
   order: {
          year: DESC
          reportParentTypeOrder: ASC
          reportTypeOrder: ASC
          title: ASC
          languageCode: ASC
          languageName:  ASC
        }
            ) {
              nodes {
                fileLocation
                id
                languageCode
                languageName
                reportParentTypeDisplayName
                reportParentTypeId
                reportParentTypeOrder
                reportTypeId
                reportTypeName
                reportTypeOrder
                subTitle
                thumbnailFileLocation
                thumbnailFileLocationFull
                title
                uploadedDate
                uploadedDateUTC
                year
              }
            }
      }
    }
  }`;
};

export const FORGOT_PASSWORD_EMAIL = (param) => {
  return `mutation {
  announcementForgetPassword(emailId: "${param?.email}" , companyCode: "${param.companyCode}") {
    message
    resetToken
    success
    userId
  }
}`;
};

export const RESET_PASSWORD = (param) => {
  return `
  mutation {
  announcementResetPassword(
    announcementResetModel:
    { newPassword: "${param?.newPassword}",    
    token: "${param?.token}",
    }
  ) {
    message
    success
  }
}`;
};
