import api from "./api";
import authAPI from "./authAPI";
import { TICKERCHARTTODAYLARGE } from "./card-query";
import {
  SIGNUP_USER,
  LOGIN_USER,
  FORGOT_PASSWORD_EMAIL,
  GET_COMPANY_INFO,
  GET_ANNOUNCEMENT_BY_DATE,
  GET_ANNOUNCEMENT_BY_ID,
  GET_ANNOUNCEMENT_QA,
  UPDATE_ANNOUNCEMENT_QA,
  UPDATE_ANNOUNCEMENT_LIKE,
  GET_FINANCIAL_REPORT,
  GET_WISE_CONFIG,
  RESET_PASSWORD,
} from "./query";

/**
 *
 * @param {Record<string, any>} params
 * @param {number} apiVersion
 * @returns {Promise<{
 *  data: {Record<string, any>}
 * }>}
 */

export const signUp = (props) =>
  authAPI.POST(null, { query: SIGNUP_USER(props) });
export const login = (props) =>
  authAPI.POST(null, { query: LOGIN_USER(props) });
export const forgot_password_email = (props) =>
  authAPI.POST(null, { query: FORGOT_PASSWORD_EMAIL(props) });
export const reset_password = (props) =>
  authAPI.POST(null, { query: RESET_PASSWORD(props) });

/** COMPANY INFORMATION */
export const getCompanyInfo = (param) => {
  return api.POST(null, { query: GET_COMPANY_INFO(param) });
};

/** GET COMPANY ANNOUNCEMENT INFORMATION BY DATE */
export const getCompanyAnnouncementByDate = (param) => {
  return api.POST(null, {
    query: `query {${GET_ANNOUNCEMENT_BY_DATE(param)}}`,
  });
};

/** GET COMPANY ANNOUNCEMENT DETAIL */
export const getCompanyAnnouncementDetail = (param) => {
  return api.POST(null, { query: GET_ANNOUNCEMENT_BY_ID(param) });
};

/** GET COMPANY ANNOUNCEMENT QA */
export const getCompanyAnnouncementQA = (param) => {
  return authAPI.POST(null, { query: GET_ANNOUNCEMENT_QA(param) });
};

/** UPDATE COMPANY ANNOUNCEMENT QA */
export const updateCompanyAnnouncementQA = (param) => {
  return authAPI.POST(null, { query: UPDATE_ANNOUNCEMENT_QA(param) });
};

/** UPDATE COMPANY ANNOUNCEMENT LIKE */
export const updateCompanyAnnouncementLike = (param) => {
  return authAPI.POST(null, { query: UPDATE_ANNOUNCEMENT_LIKE(param) });
};

/** GET FINANCIAL REPORT */
export const getFinancialReports = (param) => {
  return api.POST(null, { query: GET_FINANCIAL_REPORT(param) });
};

/** GET WISE CONFIG */
export const getWiseConfig = (param) => {
  return authAPI.GET(null, { query: GET_WISE_CONFIG(param) });
};
/** GET TICKER */
export const getTicker = (param) => {
  return api.POST(null, { query: `query { ${TICKERCHARTTODAYLARGE(param)} }` });
};



